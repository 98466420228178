import request from '@/utils/scm-request'

export function depositPage(params) {
  return request({
    url: `scm-purchase/order/depositPage`,
    method: 'get',
    params
  })
}
// 复核
export function batchApprovalDeposit(data) {
  return request({
    url: `scm-purchase/order/batchApprovalDeposit`,
    method: 'post',
    data
  })
}

// 申请定金额度
export function batchApplyDeposit(data) {
  return request({
    url: `scm-purchase/order/batchApplyDeposit`,
    method: 'post',
    data
  })
}
// 应付账龄
export function payableAccountAge(params) {
  return request({
    url: `scm-purchase/statistics/payableAccountAge`,
    method: 'get',
    params
  })
}

// 获取预测下拉时间
export function getForecastTime(params) {
  return request({
    url: `scm-purchase/statistics/getForecastTime`,
    method: 'get',
    params
  })
}

// 预测数据手动触发更新
export function forecastRefresh(params) {
  return request({
    url: `scm-purchase/statistics/forecastRefresh`,
    method: 'get',
    params
  })
}

