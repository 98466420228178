<template>
  <el-button
    :loading="importBtnLoading"
    :disabled="isDisabled"
    :type="btnType"
    @click="_ExcelExport"
  >{{ exportName }}</el-button>
</template>

<script>
import apiList from '@/api/export'
export default {
  name: 'ExcelExportFile',
  props: {
    params: {
      type: Object,
      default: () => {}
    },
    exportKey: {
      type: [String, Object],
      default: () => ''
    },
    exportName: {
      type: String,
      default: '导出'
    },

    size: {
      type: String,
      required: false,
      default: 'medium'
    },
    btnType: {
      type: String,
      require: false,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    beforeExport: {
      type: Function,
      default: () => { return true }
    }
  },

  data() {
    return {
      importBtnLoading: false
    }
  },
  methods: {

    async _ExcelExport() {
      try {
        if (this.beforeExport() === false) return false
        this.importBtnLoading = true
        // 根据之前的exportKey匹配api
        const { code } = await apiList[this.exportKey](this.params)
        if (code === 0) {
          this.$notify({
            message: '操作成功，请前往文件导出界面下载',
            type: 'success'
          })
        }
        this.importBtnLoading = false
      } finally {
        this.importBtnLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
