<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex;" :rules="rules">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="预测期" :class="$i18n.locale" prop="forecastTime">
              <el-select v-model="form.forecastTime" value-key="id" filterable>
                <el-option
                  v-for="item in forecastTimeList"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="STYLE" :class="$i18n.locale">
              <el-select v-model="form.style" value-key="id" clearable filterable @change="changeStyle">
                <el-option
                  v-for="item in styleOptions"
                  :key="item.id"
                  :label="item.styleName"
                  :value="item.styleName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="COLOR" :class="$i18n.locale">
              <el-select v-model="form.color" value-key="id" clearable filterable @visible-change="_queryColorList">
                <el-option
                  v-for="item in colorOptions"
                  :key="item.id"
                  :label="item.colorName"
                  :value="item.colorName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SIZE" :class="$i18n.locale">
              <el-select v-model="form.sizes" value-key="id" clearable filterable @visible-change="_querySizeList">
                <el-option v-for="item in sizeOptions" :key="item.id" :label="item.size" :value="item.size" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="SKU" :class="$i18n.locale">
              <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="BU" :class="$i18n.locale">
              <el-select v-model="form.bu" filterable clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  v-for="item in groupoptions"
                  :key="item.groupId"
                  :label="item.groupName"
                  :value="item.groupName"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="平台" :class="$i18n.locale">
              <el-select
                v-model="form.platformCode"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
                @change="platformchange"
              >
                <el-option
                  v-for="item in platforoptions"
                  :key="item.platformCode"
                  :label="item.platformName"
                  :value="item.platformCode"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="站点" prop="siteCode">
              <el-select v-model="form.siteCode" clearable filterable @change="$forceUpdate()">
                <el-option v-for="item in siteoptions" :key="item.id" :label="item.siteName" :value="item.siteName" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ImportButton export-key="scm_forecast" :params="exportForm" />
            <el-button type="primary" @click="refreshTime">重载数据</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="TableLoading" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{ showRow ? $t('page.hide') : $t('page.expand') }}
          </el-button>
        </el-col>
      </el-row>
    </el-form>
    <div class="el-table">
      <el-table
        ref="multipleTable"
        v-loading="TableLoading"
        show-overflow-tooltip
        class="mb-3"
        :data="tableDatas"
        :header-cell-style="{background:'#fafafa',color:'#606266'}"
        max-height="500px"
      >
        <el-table-column :label="$t('page.No')" type="index" width="80" sortable>
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          label="Style"
          min-width="110"
          prop="style"
        />
        <el-table-column
          label="Color"
          min-width="110"
          prop="color"
        />
        <el-table-column
          label="Size"
          width="100"
          prop="size"
        />
        <el-table-column
          label="供应商"
          width="100"
          prop="vendorName"
        />
        <el-table-column
          label="季节"
          width="90"
          prop="season"
        />
        <el-table-column prop="sku" label="SKU" min-width="120" class="sku" />
        <el-table-column prop="bu" label="BU" width="80" />
        <el-table-column prop="platformCode" label="渠道" min-width="140">
          <template slot-scope="scope">
            {{ scope.row.platformCode }}-{{ scope.row.siteCode }}
          </template>
        </el-table-column>
        <el-table-column prop="forecastTime" label="预测期" width="100" />
        <el-table-column
          v-for="(item, index) in yearMonth"
          :key="index"
          width="120"
          align="center"
          :prop="item"
          :label="item"
          sortable
        />
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        :current-page="pager.current"
        :page-sizes="[10,50, 100,200, 300]"
        :page-size="pager.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pager.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import ImportButton from '@/components/ExportFile'
import { getForecastTime, forecastRefresh } from '@/api/deposit-api'
import { listByPlatform, queryTerraceList, demandUnclearedForecast, queryStyleList, querySizeList, queryColorList, listBuAndOperator } from '@/api/scm-api'
// import dayjs from 'dayjs'
export default {
  components: { ImportButton },

  data() {
    return {
      forecastTimeList: [],
      siteoptions: [],
      yearMonth: [],
      form: {
        forecastTime: ''
      },
      rules: { forecastTime: { required: true, message: '必填', trigger: 'change' }},
      pager: {
        total: 0,
        size: 10,
        current: 1
      },
      groupoptions: [],
      platforoptions: [],
      tableDatas: [],
      TableLoading: false,
      showRow: false,
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      }
    }
  },
  computed: {
    _unclearedOrderParams() {
      const { size, current } = this.pager
      return Object.assign({}, { size, current }, this.form)
    },
    exportForm() {
      return Object.assign({}, { type: 3 }, this.form)
    }
  },
  created() {

  },
  mounted() {
    this._queryforcastTimeList()
    this._queryTerraceList()
    this._queryStyleList()
    this._queryBuList()
    this._unclearedOrderPage()
  },
  methods: {
    async refreshTime() {
      const { code } = await forecastRefresh()
      code === 0 && this._unclearedOrderPage()
    },
    async _queryforcastTimeList() {
      const { datas } = await getForecastTime()
      this.forecastTimeList = datas
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._unclearedOrderPage()
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._unclearedOrderPage()
    },
    platformchange() {
      const { id } = this.platforoptions.find(item => item.platformCode === this.form.platformCode) || []
      this._querySiteList(id)
      this.form.siteCode = ''
    },
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platforoptions = datas
    },
    async _querySiteList(id) {
      const { datas } = await listByPlatform({ sellPlatformId: id })
      this.siteoptions = datas
    },
    changeStyle() {
      this.form.color = ''
      this.form.size = ''
    },
    _unclearedOrderPage() {
      this.$refs['form'].validate(async(valid) => {
        if (valid) {
          try {
            this.TableLoading = true
            this.tableDatas = []
            const { code, datas: { pageData: { pager, records }, yearMonth }} = await demandUnclearedForecast(this._unclearedOrderParams)
            this.pager = pager
            this.yearMonth = yearMonth
            let arr = JSON.parse(JSON.stringify(records))
            arr = arr.map(item => JSON.parse(item.forecastPairsJson))
            // console.log(arr)
            for (var i = 0; i < records.length; i++) {
              for (var j = 0; j < arr.length; j++) {
                if (i === j) {
                  this.tableDatas.push({ ...records[i], ...arr[j] })
                }
              }
            }
            code === 0 ? this.TableLoading = false : ''
          } catch (err) {
            console.log(err)
          } finally {
            this.TableLoading = false
          }
        } else {
          return false
        }
      })
    },
    queryClick() {
      this.pager.current = 1
      this._unclearedOrderPage()
    },
    resetClick() {
      this.form = {}
      // this._unclearedOrderPage()
    },
    // style
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    // color
    async _queryColorList() {
      const { id } = this.styleOptions.find(item => item.styleName === this.form.style) || []
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    // size
    async _querySizeList() {
      const { id } = this.styleOptions.find(item => item.styleName === this.form.style) || []
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    async _queryBuList() {
      const { datas: { list }} = await listBuAndOperator()
      this.groupoptions = list
    }
  }
}
</script>

<style scoped lang="scss">
/deep/.el-table th > .cell {
    text-align: center;
}

/deep/.el-table .cell {
    text-align: center;
}
</style>
