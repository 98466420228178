import request from '@/utils/scm-request'

/**
 * @description  采购台账导出
 */
export function exportPurchaseAccount(data) {
  return request({
    url: `/scm-purchase/excel/exportPurchaseAccount`,
    method: 'post',
    data
  })
}

/**
 * @description  采购台账style维度导出
 */
export function exportPurchaseAccountStyle(data) {
  return request({
    url: `/scm-purchase/excel/exportPurchaseAccountStyle`,
    method: 'post',
    data
  })
}

/**
 * @description  采购订单导出明细
 */
export function exportPurchaseOrderDetail(data) {
  return request({
    url: `/scm-purchase/excel/exportPurchaseOrderDetail`,
    method: 'post',
    data
  })
}

/**
 * @description  采购订单导出
 */
export function exportPurchaseOrder(data) {
  return request({
    url: `/scm-purchase/excel/exportPurchaseOrder`,
    method: 'post',
    data
  })
}

/**
 * @description  采购计划导出明细
 */
export function exportPurchasePlan(data) {
  return request({
    url: `/scm-purchase/excel/exportPurchasePlan`,
    method: 'post',
    data
  })
}

/**
 * @description  采购计划导出
 */
export function exportPurchasePlanDetail(data) {
  return request({
    url: `/scm-purchase/excel/exportPurchasePlanDetail`,
    method: 'post',
    data
  })
}

export function purchaseAccountImage(data) {
  return request({
    url: `/scm-purchase/excel/purchaseAccountImage`,
    method: 'post',
    data
  })
}
export default {
  scm_payment: (data) => {
    return request({
      url: `/scm-purchase/excel/payment`,
      method: 'post',
      data
    })
  },
  scm_quality: (data) => {
    return request({
      url: `/scm-purchase/excel/quality`,
      method: 'post',
      data
    })
  },
  scm_uncleared_order: (data) => {
    return request({
      url: `/scm-purchase/excel/unclearOrder`,
      method: 'post',
      data
    })
  },
  scm_check_bill: (data) => {
    return request({
      url: `/scm-purchase/excel/checkBill`,
      method: 'post',
      data
    })
  },
  scm_check_bill_detail: (data) => {
    return request({
      url: `/scm-purchase/excel/checkBillDetail`,
      method: 'post',
      data
    })
  },
  scm_not_check_bill: (data) => {
    return request({
      url: `/scm-purchase/excel/notCheckBill`,
      method: 'post',
      data
    })
  },
  scm_not_check_bill_detail: (data) => {
    return request({
      url: `/scm-purchase/excel/notCheckBillDetail`,
      method: 'post',
      data
    })
  },
  scm_payment_log: (data) => {
    return request({
      url: `/scm-purchase/excel/paymentLog`,
      method: 'post',
      data
    })
  },
  scm_not_collect_detail: (data) => {
    return request({
      url: `/scm-purchase/excel/notCollectDetail`,
      method: 'post',
      data
    })
  },
  scm_purchase_price: (data) => {
    return request({
      url: `/scm-purchase/excel/purchasePrice`,
      method: 'post',
      data
    })
  },
  scm_order_delay: (data) => {
    return request({
      url: `/scm-purchase/excel/orderDelay`,
      method: 'post',
      data
    })
  },

  scm_uncleared_order_img: (data) => {
    return request({
      url: `/scm-purchase/excel/unclearedOrderImage`,
      method: 'post',
      data
    })
  },
  scm_vendor_offer: (data) => {
    return request({
      url: `/scm-purchase/excel/vendorOffer`,
      method: 'post',
      data
    })
  },
  scm_vendor_offer_detail: (data) => {
    return request({
      url: `/scm-purchase/excel/vendorOfferDetail`,
      method: 'post',
      data
    })
  },
  scm_forecast: (data) => {
    return request({
      url: `/scm-purchase/excel/forecast`,
      method: 'post',
      data
    })
  },
  scm_vendor_offer_po_detail: (data) => {
    return request({
      url: `/scm-purchase/excel/vendorOfferPoDetail`,
      method: 'post',
      data
    })
  },
  scm_audit_report_export: (data) => {
    return request({
      url: `/scm-purchase/excel/auditReport`,
      method: 'post',
      data
    })
  },
  scm_dsb_provision_log_export: (data) => {
    return request({
      url: `/scm-purchase/excel/provisionLog`,
      method: 'post',
      data
    })
  },
  scm_dsb_stock_provision_export: (data) => {
    return request({
      url: `/scm-purchase/excel/stockProvision`,
      method: 'post',
      data
    })
  },
  scm_dsb_stock_provision_export_can_detail: (data) => {
    return request({
      url: `/scm-purchase/excel/stockProvisionDetail`,
      method: 'post',
      data
    })
  },
  purchase_order_report: (data) => {
    return request({
      url: `/scm-purchase/excel/purchaseOrderReport`,
      method: 'post',
      data
    })
  },
  change_price_bill_page: (data) => {
    return request({
      url: `/scm-purchase/excel/changePriceBill`,
      method: 'post',
      data
    })
  },
  change_price_bill_page_detail: (data) => {
    return request({
      url: `/scm-purchase/excel/changePriceBillDetail`,
      method: 'post',
      data
    })
  },
  purchase_plan_report: (data) => {
    return request({
      url: `/scm-purchase/excel/purchasePlanReport`,
      method: 'post',
      data
    })
  }

}
